<template>
  <!-- eslint-disable max-len -->
  <section
    class="d-flex flex-column flex-wrap fill-width flex-sm-row flex-sm-nowrap buttons-container">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'ButtonsContainer',
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.buttons-container {
  margin-bottom: -15px;

  & > *:not(:last-child) {
    margin-bottom: 15px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-right: 15px;
    }
  }
}
</style>
